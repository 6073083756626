<template>
  <div class="header-desktop__wrapper" :class="headerDynamicClass">
    <div class="header-desktop__gap-filler"></div>
    <div class="header-desktop__container">
      <div class="header-desktop__main">
        <a-lazy-hydrate when-visible>
          <a-header-logo />
        </a-lazy-hydrate>

        <a-lazy-hydrate when-visible>
          <a-global-quicksearch
            class="header-desktop__globalsearch"
            :placeholder="quickSearchPlaceholder"
            with-close-icon
            hideLabel
            single-column
            scrollable
          />
        </a-lazy-hydrate>

        <client-only>
          <a-header-actions />
        </client-only>
      </div>

      <a-lazy-hydrate when-visible>
        <a-header-navigation
          :navigation-items="navigationItems"
          class="header-desktop__navigation"
        />
      </a-lazy-hydrate>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AGlobalQuicksearch from 'shared/AGlobalQuicksearch'
import AHeaderNavigation from '@/components/AHeader/AHeaderDesktop/AHeaderNavigation'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { hydrateWhenIdle } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const HEADER_COLLAPSED_CLASS_FOR_ROOT_CONTAINER = 'header-collapsed'

export default {
  name: 'AHeaderDesktop',
  mixins: [hydrationHelpers],
  components: {
    AHeaderActions: () => import('@/components/AHeader/Shared/AHeaderActions'),
    AHeaderNavigation,
    AGlobalQuicksearch,
    AHeaderLogo: hydrateWhenIdle(() =>
      import('@/components/AHeader/AHeaderDesktop/AHeaderLogo')
    )
  },
  props: {
    navigationItems: propValidator([PROP_TYPES.ARRAY])
  },
  data() {
    return {
      quickSearchPlaceholder: 'Search the website...'
    }
  },
  computed: {
    ...mapGetters({
      isHeaderCollapsed: 'isHeaderCollapsed',
      isOnboardingInProgress: 'onboarding/isOnboardingInProgress'
    }),
    headerDynamicClass() {
      return {
        collapsed:
          !this.isOnboardingInProgress &&
          (!process.client ||
            !this.$_hydrationHelpers_windowWidth ||
            this.isHeaderCollapsed)
      }
    }
  },
  watch: {
    isHeaderCollapsed: {
      immediate: true,
      handler(isCollapsed) {
        if (!process.client) return

        if (isCollapsed) {
          this.$_rootContainer_addClassToRootContainer(
            HEADER_COLLAPSED_CLASS_FOR_ROOT_CONTAINER
          )
        } else {
          this.$_rootContainer_removeClassFromRootContainer(
            HEADER_COLLAPSED_CLASS_FOR_ROOT_CONTAINER
          )
        }
      }
    }
  },
  beforeDestroy() {
    this.$_rootContainer_removeClassFromRootContainer(
      HEADER_COLLAPSED_CLASS_FOR_ROOT_CONTAINER
    )
  },
  mounted() {
    this.$emit('mounted')
  }
}
</script>

<style lang="scss" scoped>
.header-desktop__wrapper {
  height: $height-header-expanded;
  padding: 0 236px;
  margin: 0 auto;
  background: $c--header;
  color: white;
  text-align: center;

  /*
   * IMPORTANT!
   * Modify with caution, since there is JS code that rely on this value.
   * Example: onboarding flow restarts with a delay that considers this value.
   */
  transition: height 0.3s ease;

  .header-desktop__gap-filler {
    @include top-gap-filler($background: $c--black, $z-index: 1);
  }

  &.collapsed {
    height: $height-header-collapsed;

    .header-desktop__navigation {
      opacity: 0;
      overflow: hidden;
    }
  }

  .header-desktop__container {
    height: 100%;
    margin: 0 auto;
    max-width: $desktop-full-hd-container;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;

    @include desktop-lg {
      max-width: none;
    }
  }

  @include desktop-lg {
    padding: 0 15px;
  }

  .header-desktop__main {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 40px;
    margin-top: 9px;
    position: relative;
  }

  .header-desktop__globalsearch {
    width: percentage(580px / $desktop-full-hd-container);
    margin-left: 30px;
    height: 30px;
    z-index: $z-index-header-search;

    @include desktop-lg {
      width: percentage(563 / 1420);
    }

    @include desktop-md {
      width: percentage(554 / 1170);
    }

    @include desktop-sm {
      width: percentage(390 / 994);
    }

    @include tablet {
      width: percentage(195 / 738);
      margin-left: 15px;

      &:hover {
        transition: width 0.3s ease;
      }

      &.focused {
        position: absolute;
        width: 320px;
        left: 180px;
      }
    }

    /deep/ .input__icon {
      right: 5px;
    }

    /deep/ .input__wrapper {
      height: 30px;
    }

    /deep/ .input__placeholder {
      top: 2px;
      left: 5px;
    }

    /deep/ .quicksearch-results__wrapper {
      max-height: calc(100vh - 50px);
    }

    /deep/ .a-scrollable-content__content-wrapper {
      max-height: calc(100vh - 50px);
    }
  }

  .header-desktop__navigation {
    width: 100%;
    opacity: 1;
    transition: opacity 0.1s linear;
  }
}
</style>
