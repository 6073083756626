<template>
  <nav class="header-navigation__wrapper" ref="navigation">
    <ul class="header-navigation__list">
      <li
        v-for="(parentItem, index) in navigationItemsCloned"
        :key="`${parentItem.Name}${navigationItemRenderKeys[index]}`"
        :style="getNavigationListItemDynamicStyle(index)"
        class="header-navigation__list-item"
        :class="getNavigationItemClass(index)"
        :ref="generateWrapperChildRefName(index)"
        @mouseenter="openDropdown(index)"
        @mouseleave="closeDropdown(index)"
      >
        <div
          class="header-navigation__list-item-link-wrapper"
          v-bind="parentItem.attrs"
        >
          <a-link
            :to="parentItem.Link"
            :trailing-slash="$helper.isLinkWithTrailingSlash(parentItem.Link)"
            class="header-navigation__list-link"
            tabindex="0"
            @focus="openDropdown(index)"
            @blur="onNavigationItemBlur(index, $event)"
            @click="closeDropdowns"
          >
            {{ parentItem.Name }}
          </a-link>
          <template v-if="isDropdownArrowVisible(parentItem)">
            <a-icon
              :icon="ICON.ARROW_RIGHT_WHITE"
              :width="8"
              :height="8"
              class="header-navigation__arrow"
            />
            <a-header-navigation-submenu
              class="header-navigation__submenu"
              :items="parentItem.ChildItems"
              @item-click="closeDropdowns"
              @blur="onNavigationItemBlur(index, $event)"
            />
          </template>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import mixins from '@/utils/mixins'
import AHeaderNavigationSubmenu from './AHeaderNavigationSubmenu'
import AIcon, { ICON } from 'shared/AIcon'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import deepClone from 'shared/AMultiselect/utils'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { FONT } from 'shared/ACharWidth/enums'
import { HEADER_SUBMENU_ITEM_CLASS } from '@/components/AHeader/AHeaderDesktop/enums'

const ARROW_WIDTH = 12
const LIST_ITEM_PADDING_DESKTOP = 21
const LIST_ITEM_PADDING_DESKTOP_SM = 16
const LIST_ITEM_PADDING_TABLET = 20

const SUBMENU_WIDTH = 192
const SUBMENU_COUNT_REGULAR = 2
const SUBMENU_COUNT_MAX = 3

const DROPDOWN_CLASS = {
  OPENED: 'opened',
  DIRECTION_LEFT: 'left',
  DIRECTION_RIGHT: 'right'
}

export default {
  name: 'AHeaderNavigation',
  mixins: [mixins.refDimensions, hydrationHelpers],
  components: { AIcon, AHeaderNavigationSubmenu },
  props: {
    navigationItems: propValidator([PROP_TYPES.ARRAY])
  },
  data() {
    return {
      listItemsWidthValues: null,
      currentlyMovedToDropdown: [],
      navigationItemsCloned: null,
      navigationItemClasses: new Array(this.navigationItems.length).fill(''),
      navigationItemRenderKeys: new Array(this.navigationItems.length).fill(''),
      ICON
    }
  },
  computed: {
    navigationItemsLength() {
      return this.navigationItemsCloned.length
    },
    lastNavigationItem() {
      return this.navigationItemsCloned[this.navigationItemsLength - 1]
    },
    currentSidePaddingValueForItem() {
      return this.$_hydrationHelpers_getValueByBreakpoint({
        [this.$breakpoint.aboveDesktopXl]: LIST_ITEM_PADDING_DESKTOP,
        [this.$breakpoint.desktopSm]: LIST_ITEM_PADDING_DESKTOP_SM,
        [this.$breakpoint.tablet]: LIST_ITEM_PADDING_TABLET
      })
    }
  },
  watch: {
    $_hydrationHelpers_windowWidth(newVal, oldVal) {
      /**
       * Due to the fact that setting window width in store at the first page load
       * is immediately treated as a width change, the watcher is triggered on init.
       * This is not a desirable effect since in this case "setResultingNavigationItems"
       * might be called concurrently (mounted + watcher) and this may lead to unexpected
       * results
       */
      if (!oldVal) return

      this.closeDropdowns()
      this.setResultingNavigationItems()
    },
    navigationItems: {
      async handler() {
        this.cloneNavigationItems()
        this.currentlyMovedToDropdown = []
        this.calculateListItemsWidthValues()
        this.setResultingNavigationItems()
      },
      deep: true
    }
  },
  methods: {
    isDropdownArrowVisible(item) {
      return item.ChildItems && item.ChildItems.length
    },
    isChildMenuItem(el) {
      if (!el || !el.classList) return false

      return el.classList.contains(HEADER_SUBMENU_ITEM_CLASS)
    },
    onNavigationItemBlur(index, event) {
      if (this.isChildMenuItem(event.relatedTarget)) return

      this.closeDropdown(index)
    },
    generateWrapperChildRefName(index) {
      /**
       * We add "-index" to ref to avoid weird bug when focusing on one dropdown
       * while hovering on another leads to incorrect open direction of dropdown
       * due to unexpected change in ref element by index
       */
      return `navigationItemWrapper-${index}`
    },
    getItemWrapperChildRefByIndex(index) {
      return this.$refs[this.generateWrapperChildRefName(index)][0]
    },
    closeActiveDropdown() {
      const dropdownToCloseIndex = this.navigationItemClasses.findIndex(v => v)

      this.$set(this.navigationItemClasses, dropdownToCloseIndex, '')
    },
    async openDropdown(index) {
      this.closeActiveDropdown()

      const { ChildItems } = this.navigationItemsCloned[index]
      if (!ChildItems || !ChildItems.length) return

      // calculating whether to open dropdowns to left or right depending on index and
      // position
      const leftOffset = this.getItemWrapperChildRefByIndex(
        index
      ).getBoundingClientRect().left
      const windowWidthWithoutScrollbar = document.body.clientWidth

      // last item is the only item that can include 3 dropdowns
      const sumOfDropdownsWidth = this.checkIfSpecialMenuItem(index)
        ? SUBMENU_WIDTH * SUBMENU_COUNT_MAX
        : SUBMENU_WIDTH * SUBMENU_COUNT_REGULAR

      const opensToRight =
        leftOffset + sumOfDropdownsWidth < windowWidthWithoutScrollbar

      const resultingDirectionClass = opensToRight
        ? DROPDOWN_CLASS.DIRECTION_RIGHT
        : DROPDOWN_CLASS.DIRECTION_LEFT

      this.$set(
        this.navigationItemClasses,
        index,
        `${DROPDOWN_CLASS.OPENED} ${resultingDirectionClass}`
      )
    },
    /**
     * Menu item has to be re-rendered when its dropdown is about to be
     * programmatically closed in order to avoid tricky situation with tablet devices.
     * Without re-rendering, in this scenario, tapping at the menu item
     * which was programmatically closed before wouldn't result in "mouseover"
     * event to be triggered once more. Thus, destroying this menu item "resets"
     * the touch event.
     */
    reRenderDropdownBeforeClose() {
      const dropdownToCloseIndex = this.navigationItemClasses.findIndex(v => v)
      this.$set(
        this.navigationItemRenderKeys,
        dropdownToCloseIndex,
        this.$helper.guid()
      )
    },
    closeDropdowns() {
      this.reRenderDropdownBeforeClose()
      this.navigationItemClasses = new Array(this.navigationItems.length).fill(
        ''
      )
    },
    closeDropdown(index) {
      this.$set(this.navigationItemClasses, index, '')
    },
    getNavigationItemClass(index) {
      return this.navigationItemClasses[index]
    },
    getNavigationListItemDynamicStyle(index) {
      // excluding first child
      const paddingLeft = index === 0 ? 0 : this.currentSidePaddingValueForItem

      // excluding last child
      const paddingRight = this.checkIfSpecialMenuItem(index)
        ? 0
        : this.currentSidePaddingValueForItem

      return {
        padding: `0 ${paddingRight}px 5px ${paddingLeft}px`
      }
    },
    getSumOfNavItemsWidth() {
      if (!this.$_hydrationHelpers_windowWidth) {
        return
      }

      const paddingSumValue =
        (this.navigationItemsCloned.length - 1) *
        this.currentSidePaddingValueForItem *
        2

      return this.navigationItemsCloned.reduce(
        (acc, navItem) => acc + this.getNavItemWidthValue(navItem),
        paddingSumValue
      )
    },
    getCountOfItemsToMoveToDropdown(wrapperWidth, sumOfNavItemsWidth) {
      return this.navigationItemsCloned.reduceRight(
        (acc, el, index) => {
          /**
           * Special menu item cannot be moved
           */
          if (this.checkIfSpecialMenuItem(index)) {
            return acc
          }

          /**
           * First element with only paddingRight is not taken into account
           * (because it will never be the case according to the design)
           */
          const targetItemWidth =
            this.listItemsWidthValues[index] +
            2 * this.currentSidePaddingValueForItem

          if (acc.currentMenuItemsWidthSum > wrapperWidth) {
            this.currentlyMovedToDropdown.push({
              ...el,
              originalIndex: index
            })
            acc.currentMenuItemsWidthSum -= targetItemWidth
            acc.moveCount++
          }

          return acc
        },
        {
          moveCount: 0,
          currentMenuItemsWidthSum: sumOfNavItemsWidth
        }
      ).moveCount
    },
    getCountOfItemsToMoveFromDropdown(wrapperWidth, sumOfNavItemsWidth) {
      return this.currentlyMovedToDropdown.reduceRight(
        (acc, el) => {
          /**
           * First element with only paddingRight is not taken into account
           * (because it will never be the case according to the design)
           */
          const targetItemWidth =
            this.listItemsWidthValues[el.originalIndex] +
            2 * this.currentSidePaddingValueForItem

          if (acc.currentMenuItemsWidthSum + targetItemWidth <= wrapperWidth) {
            this.currentlyMovedToDropdown.pop()
            acc.moveCount++
          }

          acc.currentMenuItemsWidthSum += targetItemWidth

          return acc
        },
        {
          moveCount: 0,
          currentMenuItemsWidthSum: sumOfNavItemsWidth
        }
      ).moveCount
    },
    /**
     * The last element in the menu is considered a special case. This is the element
     * where other menu items are moved to/from when resizing
     */
    checkIfSpecialMenuItem(index) {
      if (index == null) return false

      return index === this.navigationItemsCloned.length - 1
    },
    async setResultingNavigationItems() {
      await this.$nextTick()

      if (!this.listItemsWidthValues) return

      const sumOfNavItemsWidth = this.getSumOfNavItemsWidth()

      if (!sumOfNavItemsWidth) return

      if (!this.refDimensions.navigation) return

      const { width: wrapperWidth } = this.refDimensions.navigation

      if (
        sumOfNavItemsWidth <= wrapperWidth &&
        !this.currentlyMovedToDropdown.length
      ) {
        return
      }

      if (sumOfNavItemsWidth > wrapperWidth) {
        const itemsToMoveCount = this.getCountOfItemsToMoveToDropdown(
          wrapperWidth,
          sumOfNavItemsWidth
        )

        if (!itemsToMoveCount) return

        this.moveItemsToDropdown(itemsToMoveCount)
      } else {
        const itemsToMoveCount = this.getCountOfItemsToMoveFromDropdown(
          wrapperWidth,
          sumOfNavItemsWidth
        )

        if (!itemsToMoveCount) return

        this.moveItemsFromDropdown(itemsToMoveCount)
      }
    },
    moveItemsFromDropdown(itemCount) {
      const itemsToMove = this.lastNavigationItem.ChildItems.slice(0, itemCount)

      this.navigationItemsCloned = [
        ...this.navigationItemsCloned.slice(
          0,
          this.navigationItemsCloned.length - 1
        ),
        ...itemsToMove,
        {
          ...this.lastNavigationItem,
          ChildItems: [...this.lastNavigationItem.ChildItems.slice(itemCount)]
        }
      ]
    },
    moveItemsToDropdown(itemCount) {
      const itemsToMove = this.navigationItemsCloned.slice(
        this.navigationItemsLength - itemCount - 1,
        this.navigationItemsLength - 1
      )

      this.navigationItemsCloned = [
        ...this.navigationItemsCloned.slice(
          0,
          this.navigationItemsCloned.length - itemCount - 1
        ),
        {
          ...this.lastNavigationItem,
          ChildItems: [...itemsToMove, ...this.lastNavigationItem.ChildItems]
        }
      ]
    },
    getNavItemWidthValue(navItem) {
      const arrowWidth = navItem.ChildItems.length ? ARROW_WIDTH : 0

      return (
        this.$helper.getTextWidth(navItem.Name, FONT.SANS_16_BOLD) + arrowWidth
      )
    },
    calculateListItemsWidthValues() {
      this.listItemsWidthValues = this.navigationItemsCloned.map(navItem =>
        this.getNavItemWidthValue(navItem)
      )
    },
    cloneNavigationItems() {
      this.navigationItemsCloned = deepClone(this.navigationItems)
    }
  },
  created() {
    this.cloneNavigationItems()
  },
  async mounted() {
    await this.$nextTick()

    this.calculateListItemsWidthValues()
    this.setResultingNavigationItems()
  }
}
</script>

<style lang="scss">
.header-navigation__wrapper {
  position: relative;
  max-width: $desktop-full-hd-container;

  .header-navigation__list {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
  }

  .header-navigation__list-item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    /* required to perform JS calculations */
    white-space: nowrap;

    &::after {
      display: none;
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 9px solid black;
    }

    &:first-child {
      padding-left: 0;
    }

    &.opened {
      &::after {
        display: block;
      }

      .header-navigation__submenu {
        display: block;
      }
    }

    &.right {
      .header-navigation__submenu {
        left: 0;
      }

      .header-navigation__submenu::before {
        left: 20px;
      }
    }

    &.left {
      .header-navigation-submenu__nested {
        left: auto;
        right: 100%;
      }
    }
  }

  .header-navigation__list-item-link-wrapper {
    display: flex;
    align-items: center;
  }

  .header-navigation__list-item,
  .header-navigation__list-link {
    &:hover {
      color: $c--main;
    }
  }

  .header-navigation__list-link {
    color: $c--white;
    font-family: $font-sans;
    font-weight: bold;

    & span {
      font-family: $font-sans;
    }

    &:hover + .header-navigation__arrow {
      .svg-icon {
        fill: $c--main;
      }
    }
  }

  .header-navigation__arrow {
    margin-left: 5px;

    .svg-icon {
      fill: $c--white;
    }
  }
}
</style>
